import React from 'react'
import Button from './Button'

const Calldiv = () => {
  return (
    <div className='bg-img-second flex flex-col justify-center items-center px-11 py-64 bg-[#1A3364]'>
      <p className='j-font4 pb-20 md:px-36 text-center text-white text-4xl'>Naše služby nikdy nespí! Zavolejte kdykoli – jsme tu pro vás 24 hodin denně, 7 dní v týdnu. Vaše pohodlí je naší prioritou.</p>
      <div>
        <Button />
      </div>
      
    </div>
  )
}

export default Calldiv
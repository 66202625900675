import React from "react";
import Prices from "../components/Prices";
import Herozam from "../components/Herozam";
import Footer from "../components/Footer";
import Aboutus from "../components/Aboutus";
import Notfound from '../components/Notfound';
import Calldiv from "../components/Calldiv";
import Navbar from "../components/Navbar";
import Carousell from "../components/Carousell";



function Home() {
  return (
      <div>
        <Navbar />
        <Herozam />
        <Carousell />
        <Notfound />
        <Aboutus />
        <Prices />
        <Calldiv />
        <Footer />
      </div>
  );
}

export default Home;

import React from 'react'
import Four from "../assets/Group1.png"
import '../index.css'
import Button from './Button'

const Herozam = () => {
  return (
    <div className='bg-[#F8F8F8] pb-20'>
        <div className='pt-20 pb-20 flex flex-row '>
            <div className='text-center mx-6 md:mx-6 md:w-3/5'>
                <h1 className='j-font4 fonth'>Zámečnická pohotovost 24/7</h1>
                <p className='text-2xl md:text-4xl pt-7 j-font5 sm:text-2xl fonts'>Vyrážíme ihned po zavolání, nebo se dohodneme na vyhovujícím termínu.</p>
                <div className='flex justify-center pt-24 md:pt-36 items-center'>
                    <Button />
                </div>
            </div>
            <div className='md:flex md:px-11 md:justify-center md:items-center hidden sm:w-3/5 md:w-3/5'>
                <img src={Four} alt='' className='w-full h-auto' />
            </div>
        </div>
        <div>
        </div>
    </div>
  )
}

export default Herozam
